<template>
  <Layout>
    <div class="col-sm-12 col-md-12 col-lg-12">
      <div class="mt-4 banners">
        <a target="_blank" href="https://americanfinancialtax.com/become-a-referral-partner" class="banner1">
          <img src="../../../src/assets/images/banner1.gif" alt="erc.americanfinancialtax.com" class="mb-2" id="banner1">
        </a>

        <a target="_blank" href="https://erc.americanfinancialtax.com/application" class="banner2">
          <img src="../../../src/assets/images/banner2.gif" alt="erc.americanfinancialtax.com" class="mb-2" id="banner2">
        </a>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="row img-man-2 mt-3" style="padding-right: 15px">
            <div class="col-md-12 col-lg-6">
              <div class="font-title">{{$t("paycheck_protection_program")}}</div>
              <div class="font-sub-title">
                {{$t("loan_company_lookup_search_tool")}}</div>
              <p class="font-text mt-2">
                {{$t("ppp_loan_company_lookup_search_tool_text_1")}}
              </p>
              <p class="font-text">
                {{$t("ppp_loan_company_lookup_search_tool_text_2")}}
              </p>

              <div class="btn-group btn-toggle toggle-btn mt-5 mb-4" v-if="IsadvancedSearch">
                <button @click="OnClickedBasicSearch" class="btn btn-basic-search">{{$t("basic_search")}}</button>
                <button @click="OnClickedAdvancedSearch" class="active btn btn-basic-advanced-search">{{$t("advanced_search")}}</button>
              </div>

              <div class="btn-group btn-toggle toggle-btn mt-5 mb-4" v-if="!IsadvancedSearch">
                <button @click="OnClickedBasicSearch" class="active btn btn-basic-search">{{$t("basic_search")}}</button>
                <button @click="OnClickedAdvancedSearch" class="btn btn-basic-advanced-search">{{$t("advanced_search")}}</button>
              </div>
            </div>

            <div class="col-md-12 col-lg-6">
              <div class="d-flex align-items-end flex-column">
                <div class="card-info-display">
                  <div class="key">{{$t("total_ppp_loans")}}</div>
                  <div class="value">11,468,438</div>
                </div>
                <div class="card-info-display mt-2">
                  <div class="key">{{$t("total_lenders")}}</div>
                  <div class="value">4,723</div>
                </div>
                <div class="card-info-display mt-2">
                  <div class="key">{{$t("average_ppp_loan")}}</div>
                  <div class="value">$69,112.45</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row mt-xs-5" v-if="!IsadvancedSearch">
        <div class="col-md-12 col-lg-12">
          <div class="search-panel-box">
            <div class="d-flex">
              <div class="flex-grow-1">
                <label for="company" class="form-label">{{$t("borrowername")}}</label>
                <input v-model="formdata.company_name" type="text" :placeholder="$t('type_in')" class="form-control primary-input" id="company">
              </div>
              <img @click="getLoans(1)" class="flex-shrink-0 cursor-pointer" src="@/assets/images/ico-search.png" height="65" alt="Search">
            </div>
          </div>
        </div>
      </div>
      <div v-if="IsadvancedSearch" class="row mt-xs-5">
        <div class="col-md-12 col-lg-12">
          <div class="search-panel-box">
            <div class="row">
              <div class='col-md-2'>
                <label class="form-label">{{$t("borrowerstate")}}</label>
                <select class="form-select" v-model="formdata.state">
                  <option value="">{{$t("any_state")}}</option>
                  <option v-for="(item, i) of states" :key="i" :value="item.code">{{item.name}}</option>
                </select>
              </div>
              <div class='col-md-2'>
                <label class="form-label">{{$t("zip_code")}}</label>
                <input v-model="formdata.zip_code" class="form-control" type="text" :placeholder="[[$t('zip_code')]]">
              </div>
              <div class='col-md-4'>
                <label class="form-label">{{$t("industry_support_keywords")}}</label>
                <input class="form-control" type="text" :placeholder="[[$t('industry_support_keywords')]]">
              </div>
              <div class='col-md-4'>
                <label class="form-label">{{$t("business_type")}}</label>
                <select class="form-select" v-model="formdata.business_type">
                  <option value="">{{$t("any_business_type")}}</option>
                  <option value="501(c) – Non Profit except 3,4,6,">501(c) – Non Profit except 3,4,6,</option>
                  <option value="501(c)19 – Non Profit Veterans">501(c)19 – Non Profit Veterans</option>
                  <option value="501(c)3 – Non Profit">501(c)3 – Non Profit</option>
                  <option value="Cooperative">Cooperative</option>
                  <option value="Corporation">Corporation</option>
                  <option value="Employee Stock Ownership Plan(ESOP)">Employee Stock Ownership Plan(ESOP)</option>
                  <option value="Housing Co-op">Housing Co-op</option>
                  <option value="Independent Contractors">Independent Contractors</option>
                  <option value="Joint Venture">Joint Venture</option>
                  <option value="Limited  Liability Company(LLC)">Limited Liability Company(LLC)</option>
                  <option value="Limited Liability Partnership">Limited Liability Partnership</option>
                  <option value="Non-Profit Childcare Center">Non-Profit Childcare Center</option>
                  <option value="Non-Profit Organization">Non-Profit Organization</option>
                  <option value="Partnership">Partnership</option>
                  <option value="Professional Association">Professional Association</option>
                  <option value="Qualified Joint-Venture (spouses)">Qualified Joint-Venture (spouses)</option>
                  <option value="Self-Employed Individuals">Self-Employed Individuals</option>
                  <option value="Single Member LLC">Single Member LLC</option>
                  <option value="Sole Proprietorship">Sole Proprietorship</option>
                  <option value="Subchapter S Corporation">Subchapter S Corporation</option>
                  <option value="Tenant in Common">Tenant in Common</option>
                  <option value="Tribal Concerns">Tribal Concerns</option>
                  <option value="Trust">Trust</option>
                </select>
              </div>
            </div>
            <div class='row mt-3'>
              <div class='col-md-4'>
                <label class="form-label">{{$t("employees_jobs")}}</label>
                <select class="form-select" v-model="formdata.jobsreported">
                  <option value="">{{$t("any_employees_jobs")}}</option>
                  <option value="1">200-500</option>
                  <option value="2">100-200</option>
                  <option value="3">50-100</option>
                  <option value="4">20-50</option>
                  <option value="5">10-20</option>
                  <option value="6">5-10</option>
                  <option value="7">1-5</option>
                </select>
              </div>
              <div class='col-md-8'>
                <label class="form-label">{{$t("borrowername")}}</label>
                <input v-model="formdata.company_name" class="form-control" type="text" :placeholder="[[$t('borrowername')]]">
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-4 col-md-4">
                <label class="form-label">{{$t("ppp_loan_number")}}</label>
                <input v-model="formdata.loannumber" class="form-control" type="text" :placeholder="$t('ppp_loan_number')">
              </div>
              <div class="col-lg-8 col-md-8">
                <label class="form-label">{{$t("address")}}</label>
                <input v-model="formdata.borroweraddress" class="form-control" type="text" :placeholder="$t('address')">
              </div>
            </div>
            <img @click="getLoans(1)" class="flex-shrink-0 cursor-pointer" src="@/assets/images/ico-search.png" height="65" alt="Search">
          </div>
        </div>
      </div>
      <div class="row mb-3 mt-5">
        <div class="col-lg-12">
          <BootStrapAlert/>
        </div>
        <div class="col-lg-6">
          <div class="search-info">
            Fetched <strong>{{this.data.length}}</strong> results in ~<strong>{{this.took}}</strong> seconds.
          </div>
        </div>

        <div class="col-lg-6 text-end">
          <div class="search-info">
            Searched <strong>{{this.searchCnt}}</strong> results of total <strong>{{this.totalCnt}}</strong>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class='col-sm-12'>
          <div class="table-responsive" id="table">
            <table class="table table-bordered border-light align-middle table-nowrap mb-0">
              <thead class="table-header">
              <tr>
                <th></th>
                <th scope="col" v-on:click="sortTable(item)" class="cursor-pointer"
                    v-for="(item, index) in columns_key" :key="index">
                  {{$t(item)}}
                  <i v-if="item == this.formdata.sortColumn && this.formdata.ascending"  class="las la-sort-down"></i>
                  <i v-if="item == this.formdata.sortColumn && !this.formdata.ascending" class="las la-sort-up"></i>
                </th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(row, index) of resultQuery" :key="index">
                <tr>
                  <td>
                    <span class="expandChildTable" :class="{selected: index === this.toggledIndex}" @click="this.OnClickedToggle(index)"></span>
                  </td>
                  <td>
                    <span class="company-name" v-html="this.subString(row.borrowername, 35)" @click="this.OnItemClicked(row)"></span>
                  </td>
                  <td v-html="row.borrowercity"></td>
                  <td>{{row.borrowerstate}}</td>
                  <td>{{row.borrowerzip}}</td>
                  <td>${{ this.addCommaToNumber(Math.floor(row.currentapprovalamount * 100)/100) }}</td>
                  <td>{{this.toDateString(row.dateapproved)}}</td>
                  <td>{{row.jobsreported}}</td>
                </tr>
                <tr :class="{childTableRow: index !== this.toggledIndex}" class="description text-start">
                  <td colspan="8">
                    <div class="py-1 px-2">
                      <div>Company Name: <b v-html="row.borrowername"></b></div>
                      <div>Address: <b v-html="row.borroweraddress"></b></div>
                      <div>City: <b>{{row.borrowercity}}</b></div>
                      <div>State: <b>{{row.borrowerstate}}</b></div>
                      <div>Zip: <b>{{row.borrowerzip}}</b></div>
                      <div>PPP Loan: <b>${{ this.addCommaToNumber(Math.floor(row.currentapprovalamount * 100)/100) }}</b></div>
                      <div>Jobs: <b>{{ row.jobsreported }}</b></div>
                      <div>Industry: <b>{{ row.businesstype }}</b></div>
                      <div>PPP Loan No: <b>{{ row.loannumber }}</b></div>
                      <div>Approved Date: <b>{{ this.toDateString(row.dateapproved) }}</b></div>
                      <div>Naics Name: <b>{{ row.naicsname }}</b></div>
                      <div>Loan Status: <b>{{ row.loanstatus }}</b></div>
                    </div>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
            <div v-if="resultQuery.length === 0">
              <h4 class="mt-3 text-center">{{$t("display_no_data")}}</h4>
            </div>

            <div class="d-flex justify-content-center mt-3" v-if="this.pages.length > 1">
              <div class="pagination-wrap hstack gap-2">
                <a class="page-item disabled" href="javascript:void(0)" v-if="page !== 1" @click="btnPrev">
                  <i class="las la-angle-left"></i>
                </a>
                <ul class="pagination listjs-pagination mb-0">
                  <li :class="{ active: pageNumber === page, disabled: pageNumber === '...' }"
                      v-for="(pageNumber, index) in pages.slice(page>= 5 ? page - 5:  0, page + 4)" :key="index" @click="this.getLoans(pageNumber)">
                    <a class="page" href="javascript:void(0)">{{ pageNumber }}</a>
                  </li>
                </ul>
                <a class="page-item" href="javascript:void(0)" @click="btnNext" v-if="page < pages.length">
                  <i class="las la-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>

import Layout from "@/layouts/main";
import LoanApi from "../../apis/loan-api";
import ProfileApi from "../../apis/profile-services"
import {mapGetters, mapActions} from "vuex";
import UtilFunc from "../../utils/functions";
import {states} from "@/static/states";
import BootStrapAlert from "@/components/utils/Alert";
import {
  authMethods,
} from "@/state/helpers";

export default {
  name: "home-basic-search",
  data() {
    return {
      states: states,
      title: 'Loan tracker',
      formdata: {
        state: "",
        zip_code: "",
        business_type: "",
        company_name: "",
        jobsreported: "",
        loannumber: "",
        borroweraddress: "",
        loan_size: "",
        ascending: false,
        sortColumn: ""
      },

      data: [],
      page: 1,
      perPageCnt: 50,
      totalCnt: 0,
      searchCnt: 0,
      totalPageCnt: 0,
      took: 0,
      pages: [],
      columns_key: ['borrowername', 'borrowercity', 'borrowerstate', 'borrowerzip', 'currentapprovalamount', 'dateapproved', 'jobsreported'],
      IsadvancedSearch : false,
      toggledIndex: -1,

      banner_width: "",
      banner_height: "",
    }
  },
  methods: {
    ...authMethods,
    ...mapActions("alert", ["setAlert"]),
    addCommaToNumber: UtilFunc.addCommaToNumber,
    clear() {
      this.formdata = {
        state: "",
        zip_code: "",
        business_type: "",
        company_name: "",
        loan_size: "",
        jobsreported: "",
        ascending: false,
        sortColumn: "",
        loannumber: "",
        borroweraddress: ""
      }
    },

    async getLoans(pageNum) {
      try {
        this.$isLoading(true); // show loading screen
        const result = await LoanApi.search(
            this.formdata,
            pageNum,
            this.perPageCnt
        ).finally(() => {
          this.$isLoading(false);
        });
        const {  records, totalCnt, searchCnt, totalPageCnt, took } = result;
        this.data = records;
        this.totalCnt = totalCnt;
        this.searchCnt = searchCnt;
        this.page = pageNum;
        this.totalPageCnt = totalPageCnt > 20 ? 20 : totalPageCnt;
        this.took = Math.floor(10000 * took / 1000)/10000;
        this.setPages();
      } catch (err) {
        this.setAlert({message: err.message, errors: null, color: "danger"});
      }
    },

    setPages() {
      this.pages = [];
      for (let index = 1; index <= this.totalPageCnt; index++) {
        this.pages.push(index);
      }
    },

    paginate(data) {
      return data;
    },

    btnNext() {
      this.page++;
      this.getLoans(this.page);
    },

    btnPrev() {
      this.page--;
      this.getLoans(this.page);
    },

    sortTable: function sortTable(col) {
      if (this.formdata.sortColumn === col) {
        this.formdata.ascending = !this.formdata.ascending;
      } else {
        this.formdata.ascending = true;
        this.formdata.sortColumn = col;
      }
      let ascending = this.formdata.ascending;

      this.resultQuery.sort(function(a, b) {
        if (col == "jobsreported" || col == "currentapprovalamount"){
          if (parseFloat(a[col]) > parseFloat(b[col])) {
            return ascending ? 1 : -1
          } else if (parseFloat(a[col]) < parseFloat(b[col])) {
            return ascending ? -1 : 1
          }
        }else {
          if (UtilFunc.toLowerCase(a[col]) > UtilFunc.toLowerCase(b[col])) {
            return ascending ? 1 : -1
          } else if (UtilFunc.toLowerCase(a[col]) < UtilFunc.toLowerCase(b[col])) {
            return ascending ? -1 : 1
          }
        }
        return 0;
      })
    },

    OnClickedBasicSearch(){
      if (this.IsadvancedSearch === false) return;
      this.IsadvancedSearch = false;
      this.clear();
      this.getLoans(1);
    },

    OnClickedAdvancedSearch(){
      // if (this.loggedIn){
      //   if (this.currentUser.paid_status === "canceled"){
      //     this.$router.push({
      //       name: "checkout",
      //     });
      //     return;
      //   }
      // }else {
      //   this.$router.push({name: "login"});
      // }

      if (this.IsadvancedSearch === true) return;
      this.IsadvancedSearch = true;
      this.clear();
      this.getLoans(1);
    },

    OnClickedToggle(index){
      this.toggledIndex = this.toggledIndex === index? -1 : index;
    },

    calculateBannerSize(){
      if (window.innerWidth  >= 900) {
        let p = document.getElementById("table");
        let style = window.getComputedStyle(p);
        let width = 0.5 * (window.innerWidth - parseInt(style.width)) - 20;
        this.banner_width = width + "px";
        this.banner_height = (width * 1.68) + 160 + "px";
        document.getElementById("banner1").style.width = this.banner_width;
        document.getElementById("banner2").style.width = this.banner_width;
        document.getElementById("banner2").style.position = "fixed";
        document.getElementById("banner2").style.top = this.banner_height;
      }else {
        document.getElementById("banner2").style.position = "unset";
        document.getElementById("banner1").style.width = "136px";
        document.getElementById("banner2").style.width = "136px";
      }
    },

    subString: UtilFunc.subString,
    toDateString: UtilFunc.toDateString,

    OnItemClicked(row){
      this.$router.push({
        name: 'detail',
        params: {
          borrowername: row.borrowername,
          zipcode: row.borrowerzip ? row.borrowerzip: 'null',
          id: row.id
        },
      })
    }
  },
  computed:{
    // states(){
    //   return [...states];
    // },
    displayedPosts() {
      return this.paginate(this.data);
    },
    resultQuery() {
      return this.displayedPosts;
    },
    ...mapGetters("tracker", ["loans"]),
    ...mapGetters("auth", ["currentUser", "loggedIn"]),
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  mounted() {
    this.getLoans(1);
    this.calculateBannerSize();
    window.addEventListener("resize", this.calculateBannerSize);
  },
  unmounted() {
    window.removeEventListener("resize", this.calculateBannerSize);
  },
  created() {
    //this.setPages();
    const jwt = localStorage.getItem("jwt");
    if (jwt)
      ProfileApi.profile(jwt)
          .catch(() => {
            this.logOut();
          });
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  components: {
    Layout,
    BootStrapAlert
  }
}
</script>


<style scoped>
.las {
  float: right;
  font-size: 14pt;
}

.expandChildTable:before {
  height: 1em;
  width: 1em;
  margin-top: -9px;
  display: block;
  color: white;
  border: .15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 .2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New",Courier,monospace;
  line-height: 1em;
  content: "+";
  background-color: #337ab7;
  cursor: pointer;
}

.expandChildTable.selected:before {
  content: "-";
  background-color: #ee4a57;
}
tr.childTableRow {
  display: none;
}
.description{
  background-color: #fff;
}

@media(max-width: 900px) {
  .banners{
    text-align: center;
  }
  .banner1{
    margin-right: 20px;
  }
  .banner2{
    margin-left: 20px;
    position: unset;
  }
}

@media (min-width: 900px) {
  .banner1{
    position: fixed;
    left: 0;
    top: 145px;
    z-index: 2;
  }

  .banner2{
    position: fixed;
    left: 0;
    top: 235px;
    z-index: 2;
  }
}

.company-name{
  cursor: pointer;
  color: #405189;
}

</style>