export const states = [
    {"name": "AE", "code": "AE"},
    {"name": "AK", "code": "AK"},
    {"name": "AL", "code": "AL"},
    {"name": "AR", "code": "AR"},
    {"name": "AS", "code": "AS"},
    {"name": "AZ", "code": "AZ"},
    {"name": "CA", "code": "CA"},
    {"name": "CO", "code": "CO"},
    {"name": "CT", "code": "CT"},
    {"name": "DC", "code": "DC"},
    {"name": "DE", "code": "DE"},
    {"name": "FI", "code": "FI"},
    {"name": "FL", "code": "FL"},
    {"name": "GA", "code": "GA"},
    {"name": "GU", "code": "GU"},
    {"name": "HI", "code": "HI"},
    {"name": "IL", "code": "IL"},
    {"name": "IN", "code": "IN"},
    {"name": "KS", "code": "KS"},
    {"name": "KY", "code": "KY"},
    {"name": "LA", "code": "LA"},
    {"name": "MA", "code": "MA"},
    {"name": "MD", "code": "MD"},
    {"name": "ME", "code": "ME"},
    {"name": "MI", "code": "MI"},
    {"name": "MN", "code": "MN"},
    {"name": "MO", "code": "MO"},
    {"name": "MP", "code": "MP"},
    {"name": "MS", "code": "MS"},
    {"name": "MT", "code": "MT"},
    {"name": "NC", "code": "NC"},
    {"name": "ND", "code": "ND"},
    {"name": "NE", "code": "NE"},
    {"name": "NH", "code": "NH"},
    {"name": "NJ", "code": "NJ"},
    {"name": "NM", "code": "NM"},
    {"name": "NV", "code": "NV"},
    {"name": "NY", "code": "NY"},
    {"name": "OH", "code": "OH"},
    {"name": "OK", "code": "OK"},
    {"name": "OR", "code": "OR"},
    {"name": "PA", "code": "PA"},
    {"name": "PR", "code": "PR"},
    {"name": "RI", "code": "RI"},
    {"name": "SC", "code": "SC"},
    {"name": "SD", "code": "SD"},
    {"name": "TN", "code": "TN"},
    {"name": "TX", "code": "TX"},
    {"name": "UT", "code": "UT"},
    {"name": "VA", "code": "VA"},
    {"name": "VI", "code": "VI"},
    {"name": "VT", "code": "VT"},
    {"name": "WA", "code": "WA"},
    {"name": "WI", "code": "WI"},
    {"name": "WV", "code": "WV"},
    {"name": "WY", "code": "WY"},
];